import React from 'react'

function ViewRim({rimImageSrc}) {

  return (
    <>
    <img src={rimImageSrc} alt="visuel jante"  className='h-auto'/>
    </>
  )
}

export default ViewRim